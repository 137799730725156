import React, { createContext, useContext, useState } from "react";

const PageContext = createContext();

export function PageProvider({ children }) {
    const [hidePrimary, setHidePrimary] = useState(false);

    return (
        <PageContext.Provider value={{ hidePrimary, setHidePrimary }}>
            {children}
        </PageContext.Provider>
    );
}

export function usePageContext() {
    return useContext(PageContext);
}
