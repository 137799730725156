import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import updatedProjects from "../../data/individualProjectDetails";
// import useMediaQuery from "../../hooks/useMediaQuery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//Images
import abstractPlusGrid from "../../assets/images/abstract-plus-grid-2.png";
import abstractGridDashedLines from "../../assets/images/abstract-grid-dashed-lines.png";


export default function ExampleProject() {
    const { companyName } = useParams();
    console.log("Company Name:", companyName);
    const projectDetails = updatedProjects.filter(
        (project) => project.projectCompany === companyName
    );
    const totalImages = 20;
    const imagePaths = Array.from({ length: totalImages }, (_, index) => `/images/projects/${companyName}/images/${companyName}-Image${index + 1}.png`);
    const [loadedImages, setLoadedImages] = useState([]);
    useEffect(() => {
        const preloadedImages = [];
        imagePaths.forEach((imagePath) => {
            const img = new Image();
            img.src = imagePath;
            img.onload = () => {
                preloadedImages.push(imagePath);
                if (preloadedImages.length === totalImages) {
                    setLoadedImages(preloadedImages);
                }
            };
        });
    }, [companyName, imagePaths]);

    // FOR LAYING DOWN THE UI
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": function () {
                gsap.to("#layoutImages", {
                    scrollTrigger: {
                        trigger: "#layoutImages",
                        start: "top top",
                        // pin: ".project__inner-layout-container",
                        end: "bottom bottom",
                        scrub: true,
                        nullTargetWarn: false,
                        // markers: "true",
                    },
                });
            },
        });
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": function () {
                gsap.to("#layoutImages", {
                    scrollTrigger: {
                        trigger: "#layoutImages",
                        start: "top top",
                        pin: "#layoutContent",
                        end: "bottom bottom",
                        scrub: true,
                        nullTargetWarn: false,
                        // markers: "true",
                    },
                });
            },
        });
    }, []);

    return (
        <div style={{ backgroundColor: "var(--mainGrey)" }}>
            <section>
                {projectDetails.map((project, index) => (
                    <div key={index}>
                        {/* HEADER */}
                        <div className="project__container">
                            <img
                                className="project__main-image"
                                src={project.projectMainImg}
                                alt={project.projectBusinessType}
                            />
                            <div className="project__main-info d-flex align-items-center">
                                <p className="project__main-project-number">{project.index}</p>
                                <h1 className="project__main-title">{project.projectCompany}</h1>
                            </div>
                        </div>
                        {/* PROJECT OVERVIEW */}
                        <div className="project__details-container">
                            <div className="row g-5 pb-5">
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                    <div className="project__details-section">
                                        <h4>Client</h4>
                                        <p className="project__clientName">{project.projectCompany}</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Business Type</h4>
                                        <p className="project__businessType">{project.projectBusinessType}</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Website</h4>
                                        <p className="project__website">{project.projectWebsite}</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Year(s)</h4>
                                        <p className="project__years">{project.projectYears}</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Project Budget</h4>
                                        <p className="project__budget">{project.projectBudget}</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 project__description">
                                    {project.projectDescription.map((description, descriptionIndex) => (
                                        <p key={descriptionIndex}>{description}</p>
                                    ))}
                                </div>
                            </div>
                            {/* CONTRIBUTIONS */}
                            <div className="row d-flex justify-content-end mt-5 mb-5 project__contribution-container">
                                <img
                                    src={abstractPlusGrid}
                                    alt="Grid of plus symbol."
                                    className="project__contribution-image"
                                />
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                    <h4 className="mb-5">Project Technology</h4>
                                    <div className="row g-5 pt-5">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 project__contributions-item">
                                            {project.projectTechnology.map((technology, technologyIndex) => (
                                                <p key={technologyIndex}>{technology}</p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* THE SETUP */}
                            <div className="row mt-5 pt-5 pb-5">
                                <h4 className="mb-5 project__setUp">{project.projectSetupTitle}</h4>
                                    {project.projectSetupItem.map((setup, setupIndex) => (
                                        <p key={setupIndex}>{setup}</p>
                                    ))}
                            </div>
                            {/* ENVIRONMENT & TOOLS */}
                            <div className="row mt-5 g-5 d-flex project__fileevictions-tool-box-container">
                                <div className="project__tools-box">
                                    <h4 className="mb-5">Environment & Tools</h4>
                                    <ul className="project__tool-list project__fileevictions-tool-list">
                                        {project.projectTechnology.map((technology, technologyIndex) => (
                                            <li key={technologyIndex}>{technology}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* LAYING DOWN THE DESIGN */}
                        <div id="mainContainer" className="project__layout-container clearfix">
                            <div id="layoutContainer" className="project__layout-container-inner">
                                <div id="layoutContent" className="project__layout-desc">
                                    <div id="description" className="project__layout-content-inner">
                                        <div>
                                            <h4 className="mb-5">Laying Down the UI</h4>
                                            <p className="project__layoutDetails">{project.projectLayoutDetails}</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="layoutImages" className="project__container-images">
                                    {/* {project.projectImages.map((image, imageIndex) => (
                                        <img
                                            key={imageIndex}
                                            src={image}
                                            className="img-fluid mb-5 project__images"
                                            alt={project.projectCompany}
                                        />
                                    ))} */}
                                    {loadedImages.map((image, imageIndex) => (
                                        <img
                                            key={imageIndex}
                                            src={image}
                                            className="img-fluid mb-5 project__images"
                                            alt={companyName}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* DETAILS ONE */}
                        <div className="project__details-container row pt-5 pb-5 mt-5">
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-2 order-md-1 order-lg-1"></div>
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-1 order-md-2 order-lg-2">
                                <h4 className="mb-5 project__moreDetailsOne">It began with several sketches</h4>
                                    {project.projectDetailsOne.map((detail, detailIndex) => (
                                        <p key={detailIndex}>{detail}</p>
                                    ))}
                            </div>
                        </div>
                        <div
                            className="project__details-container project__additional-container project__moreImagesOne row d-flex align-items-center justify-content-center mb-5"
                            style={{
                                // minHeight: "100vh",
                                minHeight: "-webkit-fill-available",
                            }}
                        >
                            <div style={{ zIndex: "10", width: "80%" }}>
                                {project.projectDetailsOneImages.map((image, imageIndex) => (
                                    <img
                                        key={imageIndex}
                                        src={image}
                                        className="img-fluid mb-5 project__images"
                                        alt={project.projectCompany}
                                    />
                                ))}
                            </div>
                            <img
                                src={abstractGridDashedLines}
                                alt="Screenshots of original website structure."
                                className="project__additional-abstract-image"
                            />
                        </div>
                        {/* END DETAILS ONE */}
                        {/* DETAILS TWO */}
                        <div className="project__details-container row pt-5 pb-5 mt-5">
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-2 order-md-1 order-lg-1"></div>
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-1 order-md-2 order-lg-2">
                                <h4 className="mb-5 project__moreDetailsTwo">It began with several sketches</h4>
                                    {project.projectDetailsTwo.map((detail, detailIndex) => (
                                        <p key={detailIndex}>{detail}</p>
                                    ))}
                            </div>
                        </div>
                        <div
                            className="project__details-container project__additional-container project__moreImagesTwo row d-flex align-items-center justify-content-center mb-5"
                            style={{
                                // minHeight: "100vh",
                                minHeight: "-webkit-fill-available",
                            }}
                        >
                            <div style={{ zIndex: "10", width: "80%" }}>
                                {project.projectDetailsTwoImages.map((image, imageIndex) => (
                                    <img
                                        key={imageIndex}
                                        src={image}
                                        className="img-fluid mb-5 project__images"
                                        alt={project.projectCompany}
                                    />
                                ))}
                            </div>
                            <img
                                src={abstractGridDashedLines}
                                alt="Screenshots of original website structure."
                                className="project__additional-abstract-image"
                            />
                        </div>
                        {/* END DETAILS TWO */}
                        {/* DETAILS THREE */}
                        <div className="project__details-container row pt-5 pb-5 mt-5">
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-2 order-md-1 order-lg-1"></div>
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-1 order-md-2 order-lg-2">
                                <h4 className="mb-5 project__moreDetailsOne">It began with several sketches</h4>
                                    {project.projectDetailsThree.map((detail, detailIndex) => (
                                        <p key={detailIndex}>{detail}</p>
                                    ))}
                            </div>
                        </div>
                        <div
                            className="project__details-container project__additional-container project__moreImagesOne row d-flex align-items-center justify-content-center mb-5"
                            style={{
                                // minHeight: "100vh",
                                minHeight: "-webkit-fill-available",
                            }}
                        >
                            <div style={{ zIndex: "10", width: "80%" }}>
                                {project.projectDetailsThreeImages.map((image, imageIndex) => (
                                    <img
                                        key={imageIndex}
                                        src={image}
                                        className="img-fluid mb-5 project__images"
                                        alt={project.projectCompany}
                                    />
                                ))}
                            </div>
                        </div>
                        {/* END DETAILS THREE */}
                    </div>
                ))}
            </section>
        </div>
    );
}
