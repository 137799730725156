import React from "react";
import borderlandsLogo from "../../../assets/images/portfolio/borderlands-echovision-logo.png";
import borderlands from "../../../assets/images/portfolio/borderlands-lg.png";

export default function ProfessionalProjects() {
    return (
        <section id="proProjectsBorderlands" className="proProjects__container borderlands__container anchor ">
            <div className="projects__yellowFlourish"></div>
            {/* Projects List */}
            <div className="container">
                <div className="box row projects__row borderlands__row">
                    <div className="proProjects__textBox col-lg-7 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2">
                        <p className="proProjects__projectTitle">Borderlands: Echovision Live</p>
                        <p className="proProjects__description">My team build an exciting upcoming massively interactive live event. I hired and led the team building out the interactive portion and integrating Genvid's proprietary SDK to broadcast gameplay, minigames, chat, and more.</p>
                        <p className="proProjects__description">I handled hiring for devs, QA, project manager, and producers, driving early best practices, coaching, delivering milestones, and also filling in on project management and production.</p>
                        <p className="proProjects__description">We worked on minigames, avatar builders, in-app currency purchasing, spending, and balancing, and season pass!</p>
                        <div className="primary-button__container">
                            <div className="proProject__capsule capsule">Engineering Mgmt</div>
                            <div className="proProject__capsule capsule">Production</div>
                            <div className="proProject__capsule capsule">Project Mgmt</div>
                        </div>
                        <div className="primary-button__container">
                            <hr className="primary-button__line" />
                            <a
                                href="/project/Genvid"
                                rel="noreferrer"
                            >
                                <button className="primary-button">More Details</button>
                            </a>
                        </div>
                    </div>
                    <div className="proProjects__project-container col-lg-7 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                        <div className="borderlands__imageBlocks">
                            <img
                                src={borderlands}
                                className="proProjects__image proProjects__primary-image"
                                alt="Borderlands: Echovision Live"
                            />
                            <img
                                src={borderlandsLogo}
                                className="proProjects__secondary-image"
                                alt="Borderlands: Echovision Live Logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
