import React from "react";
import { usePageContext } from "../PageContext";

export default function AboutProfessional() {
    const numberOfLines = 25;
    const { hidePrimary } = usePageContext();
    return (
        <section id="aboutProfessional" className="about__container anchor">
            {/* About Content and Image */}
            <div className="container">
                <div className="row aboutPro__row about__row">
                    <div className="about__content col-lg-1 col-md-1 col-sm-1 col-1">
                        <div className="about__minilinesContainer minilinesContainer">
                            {Array.from({ length: numberOfLines }, (_, index) => (
                                <div key={index} className="about__minilines minilines"></div>
                            ))}
                        </div>
                    </div>
                    <div className="about__content col-lg-9 col-md-6 col-sm-9 col-9">
                        <p>I have been in the development game for 15 years now at many different levels from individual contributor, to project manager, producer, and engineering manager. I have built and mentored many excellent teams focused on the values: <span className="contentHighlight">diversity</span>, <span className="contentHighlight">culture</span>, <span className="contentHighlight">passion</span>, <span className="contentHighlight">communication</span>, <span className="contentHighlight">collaboration</span>, <span className="contentHighlight">dependability</span>, and <span className="contentHighlight">delivery</span>.</p>
                        <p>I love working as the glue for our projects and filling in the blanks as a generalist wherever needed. In the past this meant I worked on developing complex technical solutions around choosing a new chat platform serving 20 million live users or researching the infrastructure needed for a large-scale rental mobile app.</p>
                        <p>For over a decade I have been committed to lead my teams by empowerment and I fully embrace the servant leadership methodology. Building <span className="contentHighlight">highly-performant</span> teams is where I excel and my super power is being someone people like to work with not only on my team but also my partners company-wide.</p>
                        {/* Conditional Button Group */}
                        {!hidePrimary && (
                            <div className="primary-button__container col-lg-6">
                                <a href="/#proProjects" rel="noreferrer">
                                    <button className="primary-button">View Game Work</button>
                                </a>
                                <a href="/#webProjects" rel="noreferrer">
                                    <button className="primary-button">View Web Work</button>
                                </a>
                            </div>
                        )}
                        {/* End Conditional Buttons */}
                    </div>
                </div>
            </div>
        </section>
    );
}
