import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import ExampleProject from "./pages/home/ExampleProject";
import Genvid from "./projects/Genvid";
// import ProjectDetail from "./pages/home/ProjectDetail";
import "./App.css";

//Frame Components
import Sidebar from "./pages/home/components/Sidebar";
import Contact from "./pages/home/components/Contact";

function App() {
    // let location = useLocation();

    return (
        <div style={{ backgroundColor: "var(--mainGrey)" }}>
            <Sidebar />
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/project/:companyName"
                    element={<ExampleProject />}
                />
                <Route
                    path="/project/Genvid"
                    element={<Genvid />}
                />
            </Routes>
            <Contact />
        </div>
    );
}

export default App;
