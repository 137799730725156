import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { PageProvider } from './pages/home/PageContext';
import { createRoot } from "react-dom/client";
import "bootstrap-icons/font/bootstrap-icons.css";
import App from './App';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
        <PageProvider>
            <App />
        </PageProvider>
    </Router>
  </React.StrictMode>,
);