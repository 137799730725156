import React from "react";
import toolkitIcons from "../../../data/toolkit";
import skillset from "../../../data/skillset";

export default function Toolkit() {
    return (
        <section id="toolkit" className="toolkit__container anchor">
            <div className="container">
                <div className="row toolkit__row">
                    {/* Section Title */}
                    <div className="toolkit__title toolkit__sectionTitleContainer sectionTitleContainer">
                        <h1>
                            <span className="toolkit__sectionTitle sectionTitle">Toolkit </span>
                            <span className="toolkit__script">& </span>
                            <span>Skills</span>
                        </h1>
                    </div>
                </div>
                <div className="row toolkit__row toolkit__iconRow">
                    {/* Skills Icons */}
                    <div className="toolkit__icon-container col-lg-5 col-md-12 col-sm-12 col-12">
                        {toolkitIcons.map(({ id, altText, image }) => (
                            <div key={id}>
                                <img
                                    src={image}
                                    alt={altText}
                                    width="auto"
                                    height="35px"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="toolkit__subtitle col-lg-5 col-md-12 col-sm-12 col-12">
                        {skillset.map((skillsGroup, groupIndex) => (
                            skillsGroup.skills.map((skill, skillIndex) => (
                                <span key={groupIndex + "-" + skillIndex}>
                                    {skill}
                                    {skillIndex !== skillsGroup.skills.length -1 && " • "}
                                </span>
                            ))
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
