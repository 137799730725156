import React from "react";
import { useLocation } from "react-router-dom";
import { usePageContext } from "./PageContext";
// Main
import Header from "../home/components/Header";
import About from "../home/components/About";
import AboutProfessional from "../home/components/AboutProfessional";
import Toolkit from "../home/components/Toolkit";
// Game stuff
import ProjectBorderlands from "./components/ProjectBorderlands";
import ProjectSilentHill from "./components/ProjectSilentHill";
// import PersonalProject from "./components/ProjectUpcoming";
//Web Stuff
import ProjectStreaming from "./components/ProjectStreaming";
import WebProjects from "../home/components/WebProjects";


export default function Home() {
    return (
        <div>
            <Header />
            <About />
            <AboutProfessional />
            <PageRouteRenderer />
            <WebProjects />
            <Toolkit />
        </div>
    );
}
function PageRouteRenderer() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("work");
    const { setHidePrimary } = usePageContext();

    switch (page) {
        case "web":
            setHidePrimary(true);
            return <ProjectStreaming />;
        default:
            setHidePrimary(false);
            return (
                <>
                    <ProjectBorderlands />
                    <ProjectSilentHill />
                    {/* <PersonalProject /> */}
                </>
            );
    }
}