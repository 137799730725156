import React from "react";
import linkedinIcon from "../../../assets/icons/linkedin-in-brands.png";
import emailIcon from "../../../assets/icons/email-add-black.png";

export default function Contact() {
    return (
        <section id="contact" className="contactHome__main-container anchor">
            {/* Contact Section */}
            <div className="contactHome__container">
                <div className="row g-5 d-flex">
                    <div className="contact__row contact__left col-lg-10 col-md-12 col-sm-12">
                        <h3>Contact</h3>
                        <h1 className="contact__goodbye">The journey doesn't have to end. Hit a contact below and we can get <span className="contact__highlight">started</span>.</h1>
                    </div>
                    <div className="contact__row contact__right col-lg-6 col-md-12 col-sm-12">
                        <div>
                            <a
                                href="mailto:sean.samborski@live.com"
                                role="button"
                                className="d-flex justify-content-between align-items-center contact-links"
                            >
                                Email
                                <i className="bi bi-arrow-right contact-links-arrow"></i>
                            </a>
                            <hr className="contact-links-divider" />
                            <a
                                href="https://www.linkedin.com/in/seansamborski/"
                                rel="noreferrer"
                                target="_blank"
                                role="button"
                                className="d-flex justify-content-between align-items-center contact-links"
                            >
                                Linkedin
                                <i className="bi bi-arrow-right contact-links-arrow"></i>
                            </a>
                            <hr className="contact-links-divider" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <div className="row contact__home-footer-container">
                <div className="col-10 contact__home-footer">
                    <p>Designed with &lt;3 in <span className="contentHighlight">Adobe XD</span> and built with <span className="contentHighlight">React</span>.</p>
                    <div className="contact__menu-footer-icons">
                        <a
                            href="https://www.linkedin.com/in/seansamborski/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img
                                src={linkedinIcon}
                                alt="Linkedin Icon."
                                className="sidebar__menu-contact-icon"
                            />
                        </a>
                        <a href="mailto:sean.samborski@live.com">
                            <img
                                src={emailIcon}
                                alt="Email Icon."
                                className="sidebar__menu-contact-icon"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
