import react from "../assets/icons/react.svg";
import javascript from "../assets/icons/js.svg";
import nodejs from "../assets/icons/node-js.svg";
import typescript from "../assets/icons/typescript_icon.svg";
import html from "../assets/icons/html5.svg";
import css3 from "../assets/icons/css3-alt.svg";
import sass from "../assets/icons/sass_icon.svg";
import figma from "../assets/icons/figma.svg";
import adobeXd from "../assets/icons/Adobe_xd_icon.svg";
import illustrator from "../assets/icons/Adobe_illustrator_icon.svg";
import unity from "../assets/icons/unity.svg";
import github from "../assets/icons/git-alt.svg";
import postgres from "../assets/icons/postgres_icon.svg";
import mysql from "../assets/icons/mysql_icon.svg";
import aws from "../assets/icons/aws.svg";
import atlassian from "../assets/icons/atlassian_icon.svg";
import jira from "../assets/icons/jira.svg";
import csharp from "../assets/icons/c_sharp_icon.svg";
import slack from "../assets/icons/slack_icon.svg";
import google from "../assets/icons/google_play_icon.svg";
import apple from "../assets/icons/app_store_icon.svg";
import steam from "../assets/icons/steam_icon.svg";
import docker from "../assets/icons/docker_icon.svg";



const toolkitIcons = [
    {
        title: "Unity",
        altText: "Unity Icon",
        image: unity,
    },
    {
        title: "C#",
        altText: "C# Icon",
        image: csharp,
    },
    {
        title: "React",
        altText: "React Icon",
        image: react,
    },
    {
        title: "JavaScript",
        altText: "JavaScript Icon",
        image: javascript,
    },
    {
        title: "Typescript",
        altText: "Typescript Icon",
        image: typescript,
    },
    {
        title: "Node JS",
        altText: "Node JS Icon",
        image: nodejs,
    },
    {
        title: "HTML",
        altText: "HTML Icon",
        image: html,
    },
    {
        title: "Sass",
        altText: "Sass Icon",
        image: sass,
    },
    {
        title: "CSS",
        altText: "CSS Icon",
        image: css3,
    },
    {
        title: "Figma",
        altText: "Figma Icon",
        image: figma,
    },
    {
        title: "Adobe XD",
        altText: "Adobe XD Icon",
        image: adobeXd,
    },
    {
        title: "Adobe Illustrator",
        altText: "Adobe Illustrator Icon",
        image: illustrator,
    },
    {
        title: "Github",
        altText: "Github Icon",
        image: github,
    },
    {
        title: "Jira",
        altText: "Jira Icon",
        image: jira,
    },
    {
        title: "Atlassian",
        altText: "Atlassian Icon",
        image: atlassian,
    },
    {
        title: "AWS",
        altText: "AWS Icon",
        image: aws,
    },
    {
        title: "MySQL",
        altText: "MySQL Icon",
        image: mysql,
    },
    {
        title: "PostgreSQL",
        altText: "Postgres Icon",
        image: postgres,
    },
    {
        title: "Slack",
        altText: "Slack Icon",
        image: slack,
    },
    {
        title: "Google Play",
        altText: "Google Play Icon",
        image: google,
    },
    {
        title: "Apple App Store",
        altText: "App Store Icon",
        image: apple,
    },
    {
        title: "Docker",
        altText: "Docker Icon",
        image: docker,
    },
    {
        title: "Steam",
        altText: "Steam Icon",
        image: steam,
    },
];

export default toolkitIcons;
