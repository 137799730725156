import React from "react";
import silentHill from "../../../assets/images/portfolio/silent-hill-lg.png";
import silentHillGif from "../../../assets/images/portfolio/silent-hill-1.gif";

export default function ProfessionalProjects() {
    return (
        <section id="proProjectsSilentHill" className="proProjects__container silentHill__container anchor">
            <div className="container">
                <div className="box row projects__row silentHill__row">
                    <div className="proProjects__project-container col-lg-7 col-md-12 col-sm-12 col-12">
                        <div className="silentHill__imageBlocks">
                            <img
                                src={silentHillGif}
                                className="proProjects__image proProjects__primary-image"
                                alt="Silent Hill: Ascension"
                            />
                        </div>
                        <div className="silentHill__yellowFlourish"></div>
                    </div>
                    <div className="proProjects__textBox col-lg-5 col-md-12 col-sm-12 col-12">
                        <div className="proProjects__overlay"></div>
                        <p className="proProjects__projectTitle">Silent Hill: Ascension</p>
                        <p className="proProjects__description">My team collaborated on the next iteration of Genvid's MILE. Our team assisted in porting over our work on authentication, chat, moderation, and custom avatar builders.</p>
                        <p className="proProjects__description">We also researched and built out the mobile asset delivery and production pipeline to the app stores. Our solution got Silent Hill up and running in iOS and Android apps.</p>
                        <p className="proProjects__description">Our work on in-game currency earning, spending, and balance was adopted as well.</p>
                        <div className="primary-button__container">
                            <div className="proProject__capsule capsule">Engineering Mgmt</div>
                            <div className="proProject__capsule capsule">Production</div>
                            <div className="proProject__capsule capsule">Project Mgmt</div>
                        </div>
                        <div className="primary-button__container">
                            <hr className="primary-button__line" />
                            <a
                                href="/project/Genvid"
                                rel="noreferrer"
                            >
                                <button className="primary-button">More Details</button>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="primary-button__container">
                        <hr className="primary-button__line" />
                        <a
                            href={resumePDF}
                            download="Samborski Resume"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <button className="primary-button">View Resume</button>
                        </a>
                    </div>
                    <div className="primary-button__container proProjects__button">
                        <hr className="primary-button__line" />
                        <a href="/projects">
                            <button className="primary-button">Case Studies</button>
                        </a>
                    </div>
                </div> */}
            </div>
        </section>
    );
}
