const skillset = [
  {
    skillGroup: "Engineering Leadership",
    skills: [
        "Mentorship",
        "Empowerment",
        "Team Culture & Values",
        "Development Team Leadership",
        // "Estimation",
        "Software Solutions",
        // "ERP Design & Implementation",
        // "Web Development",
        // "Ecommerce",
        "Development Processes",
        "1:1s",
        "Performance Reviews",
        // "Investigation / Research",
        // "Mobile Release",
        // "Remote Team Management",
        // "Development Delivery",
        // "Deployment Infrastructure Coordination",
        // "Solution Wireframing",
    ],
  },
  {
    skillGroup: "Production",
    skills: [
        "Roadmapping",
        // "Internal / External Coordination",
        // "Co-Dev Management",
        "Wireframing",
        "Documentation",
        "Budgeting",
        // "Priorities",
        "Training",
        "Cross-Team Communication",
        "Best Practices",
        // "Process",
        // "QA",
        // "Testing",
        // "Load Testing",
        // "Minigames",
    ],
  },
  {
    skillGroup: "Project Management",
    skills: [
        "Planning",
        "AGILE",
        "Project Breakdown",
        "Program Management",
        "Story Mapping",
        // "Project Roadmaps",
        // "Scrum Ceremonies",
        // "Technical Syncs",
        "Standups",
        "Sprint Planning",
        // "Refinement",
        "Retros",
        "Status Updates",
        "Release Notes",
        "Team Goals",
    ],
  },
  {
    skillGroup: "Recruiting",
    skills: [
        "Team Recruiting",
        // "Job Descriptions",
        "Interviewing",
        "Sourcing",
        "Evaluating Team Needs",
        "Onboarding",
        "Job Success Profiles",
    ],
  },
  {
    skillGroup: "Product Ownership",
    skills: [
        "Scoping",
        "Requirements",
        // "Product Ideation",
        "Project Design",
        "Customer Journeys",
        "Feature Ideation",
        "Research",
        "UX Mockups",
        "Documentation",
        "Iteration",
        "Prototyping",
        "Partner Demos",
        "Product Proposals",
    ],
  },
//   {
//     skillGroup: "Developement",
//     skills: [
//         // "Javascript",
//         // "React",
//         // "Typescript",
//         // "C#",
//         // "PostGres/MySQL",
//         // "DocumentDB/DynamoDB",
//         // "API Libraries",
//         // "Analytics Tracking",
//         // "Authentication",
//         // "Capacitor",
//         // "Frontend Development",
//         // "CMS",
//         // "Chat",
//         // "Mobile Development & Delivery",
//         // "Moderation",
//         // "Mobile Update Asset Delivery (PAD/ODR)",
//         // "Amazon IVS",
//         // "PlayFab",
//         // "Frontend Component Libraries",
//     ],
//   },
];

export default skillset;
