import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//Images
import abstractPlusGrid from "../assets/images/abstract-plus-grid-2.png";
import abstractGridDashedLines from "../assets/images/abstract-grid-dashed-lines.png";
import borderlandsMain from "../assets/images/portfolio/borderlands-lg.png";
import borderlandsLogo from "../assets/images/portfolio/borderlands-echovision-logo@2x.png";
import silentHillMain from "../assets/images/projects/Genvid/silent-hill-main.png";
import silentHillLumbering from "../assets/images/projects/Genvid/silent-hill-lumbering.png";
import silentHillShotgun from "../assets/images/projects/Genvid/silent-hill-shotgun.png";
import silentHillBaddie from "../assets/images/projects/Genvid/silent-hill-baddie.png";
import silentHillTrauma from "../assets/images/projects/Genvid/silent-hill-trauma.png";
import silentHillFate from "../assets/images/projects/Genvid/silent-hill-fate.png";
import walkingDeadShooter from "../assets/images/projects/Genvid/walking-dead-shooter.png";
import walkingDeadMain from "../assets/images/projects/Genvid/walking-dead-main.jpeg";

export default function Genvid() {

    const [scrolled, setScrolled] = useState(false);

    const removeClassToExternalElement = () => {
        const externalElement = document.getElementById("SidebarMenu");
        if (externalElement) {
            externalElement.classList.remove("unscrolled");
        }
    };
    useEffect(() => {
        const externalElement = document.getElementById("SidebarMenu");
        if (externalElement) {
          externalElement.classList.add("unscrolled");
        }
      }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY >= window.innerHeight) {
                setScrolled(true);
                removeClassToExternalElement();
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    // FOR LAYING DOWN THE UI
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": function () {
                gsap.to("#layoutImages", {
                    scrollTrigger: {
                        trigger: "#layoutImages",
                        start: "top top",
                        // pin: ".project__inner-layout-container",
                        end: "bottom bottom",
                        scrub: true,
                        nullTargetWarn: false,
                        // markers: "true",
                    },
                });
            },
        });
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": function () {
                gsap.to("#layoutImages", {
                    scrollTrigger: {
                        trigger: "#layoutImages",
                        start: "top top",
                        pin: "#layoutContent",
                        end: "bottom bottom",
                        scrub: true,
                        nullTargetWarn: false,
                        // markers: "true",
                    },
                });
            },
        });
    }, []);

    return (
        <div style={{ backgroundColor: "var(--mainGrey)" }}>
            <section>
                    <div>
                        {/* HEADER */}
                        <div className="project__container">
                            <img
                                className="project__main-image"
                                src={borderlandsMain}
                                alt="Borderlands Main"
                            />
                            <img
                                className="project__main-image"
                                src={borderlandsMain}
                                alt="Borderlands Main"
                            />
                            <div className="project__main-info d-flex align-items-center">
                                <p className="project__main-project-number">01</p>
                                <h1 className="project__main-title">Genvid</h1>
                            </div>
                        </div>
                        {/* PROJECT OVERVIEW */}
                        <div className="project__details-container">
                            <div className="row g-5 pb-5">
                                <img
                                    className="project__mainLogo"
                                    src={borderlandsLogo}
                                    alt="Borderlands Main Logo"
                                />
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                    <div className="project__details-section">
                                        <h4>Client</h4>
                                        <p className="project__clientName">Genvid</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Business Type</h4>
                                        <p className="project__businessType">Massively Interactive Live Streaming Game</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Website</h4>
                                        <p className="project__website">GenvidTech.com</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Year(s)</h4>
                                        <p className="project__years">2022-2023</p>
                                    </div>
                                    <div className="project__details-section">
                                        <h4>Project Budget</h4>
                                        <p className="project__budget">$10mil+</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 project__description">
                                    <p>Streaming has never been more exciting than with an entire community together changing the course of the story.</p>
                                    <p>Think Tell Tale meets live broadcasting meets massively multiplayer and add in a one-time live event where the community decides the real canon story. That’s what you get with titles I’ve worked on Borderlands: Echovision Live and Silent Hill: Ascension.</p>
                                    <p>Each day, a part of the story is broadcasted and ends with a decision that needs to be made in the story. As a player, you get to decide with the community the decision that shapes the future. While not watching broadcasts, you can vote on the upcoming choices, build an avatar and customize it with cosmetics you’ve earned, play minigames, chat with the community, and more.</p>
                                    <p>As the engineering manager for the project team, I led the team responsible for building out these systems the players use to interact with the game. We built out monetisation features as well such as season pass, earning and purchasing in-app currency, authentication, chat moderation, and more.</p>
                                </div>
                            </div>
                            {/* CONTRIBUTIONS */}
                            <div className="row d-flex justify-content-end mt-5 mb-5 project__contribution-container">
                                <img
                                    src={abstractPlusGrid}
                                    alt="Grid of plus symbol."
                                    className="project__contribution-image"
                                />
                            </div>
                            {/* THE SETUP */}
                            <div className="row mt-5 pt-5 pb-5">
                                <h4 className="mb-5 project__setUp">Primary Role: Engineering Manager</h4>
                                <p>I took on multiple roles for the team. I joined in pre-production as the Engineering Manager with one developer on the team. From there, I hired out the interaction development team and managed our remote members in Japan and Malaysia as well. I sourced, interviewed, and hired my development team, producers, and project managers at all stages from evaluating team needs, job description authoring, interviewing and assessment, onboarding, and job success.</p>
                                <p>I served as the main project technical leadership running the team responsible for building all user activity features including: Season Pass functionality and reward management; Bidding; Leaderboards; Earning, spending, and purchasing in-game currency; Avatar builder and color swapping customization; Integration of game designer balancing CMS; Currency spend rewards (Gatcha); and more.</p>
                                <p>My team scoped, built, and helped design complex quick time event interactions in the user overlay, including rapidly developing prototypes such as rhythm tapper activities, POCs (proof-of-concept apps) for partner demo, acceptance, and tight feedback loops.</p>
                                <p>My team developed the first investigation and mobile releases to the app stores as well as new and emerging streaming technologies. I wrote, evaluated, and delivered product proposals for large scale systems, mobile app delivery pipeline, CMS new third-party software integrations for features such as user chat and mobile update asset delivery (PAD/ODR), and others.</p>
                                <p>I ran the QA process and managed the QA effort on our project, including research into the requirements and technical needs for load balancing and testing. I conducted my own hands-on testing of the gameplay and reported numerous bugs, maintained the tracker, and coordinated status and priority of the fixes with our co-dev QA team and our internal QA developers. I also created and managed the technical debt log for the project and collaborated with producers on the priority and scheduling of technical debt.</p>
                                <p>Additional duties included the overseeing of integration with the Genvid streaming SDK, Amazon IVS implementation, evaluating infrastructure requirements and setup, researching and proposing large-scale third party integrations for analytics tracking, game designer balancing system, chat and community moderation tools, and more.</p>
                            </div>
                            <div className="row mt-5 pt-5 pb-5">
                                <h4 className="mb-5 project__setUp">Secondary Roles: Producer, Project Management</h4>
                                <p>During almost the first year on the project, our project didn't have a dedicated producer and project manager so I stepped in to help fulfill those duties. I coordinated day-to-day AGILE ceremonies including technical syncs, scrums, standups, sprint planning, refinement, retros, status updates, and sprint release documentation. I cross-managed product teams internally and externally filling in on production and project management to drive planning, roadmapping, estimating developer efforts, scoping and developing product requirements, priorities, cross-team communication, collaboration between internal and external teams, and managing the entire game lifecycle. I worked directly with executive stakeholders and creative directors on product ideation, UI/UX, and full scoping of new features.</p>
                                <p>During pre-production, I coordinated, scheduled, and ran the producer and project manager workshop onsite in LA. I trained our internal and co-dev partners, as well as the narrative partners, in best-practices, JIRA and Confluence use, roadmapping, and setting Milestones. I coordinated the efforts on the first project roadmap, milestones, and vertical slice deliveries.</p>
                                <p>Additional requirements was setting and delivering team goals, coordinating with co-dev partners daily and weekly, project requirements review, scoping, and documenting.</p>
                            </div>
                            {/* ENVIRONMENT & TOOLS */}
                            <div className="row mt-5 g-5 d-flex project__tool-box-container">
                                <div className="project__tools-box">
                                    <h4 className="mb-5">Environment & Tools</h4>
                                    <ul className="project__tool-list project__tool-list">
                                        <li>Live Streaming</li>
                                        <li>Massively Multiplayer</li>
                                        <li>Avatar Creator</li>
                                        <li>Decision Bidding</li>
                                        <li>Quick Time Events</li>
                                        <li>Shooting Minigame</li>
                                        <li>Puzzle Minigames</li>
                                        <li>Cosmetics</li>
                                        <li>In-App Currency</li>
                                        <li>Season Pass</li>
                                        <li>Chat</li>
                                        <li>Moderation</li>
                                        <li>Wallet</li>
                                        <li>Authentication</li>
                                        <li>iOS, Android, Steam</li>
                                        <li>Unreal 5</li>
                                        <li>Amazon IVS</li>
                                        <li>Amazon AWS</li>
                                        <li>Elasticache</li>
                                        <li>DynamoDB</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* LAYING DOWN THE DESIGN */}
                        <div id="mainContainer" className="project__layout-container clearfix">
                            <div id="layoutContainer" className="project__layout-container-inner">
                                <div id="layoutContent" className="project__layout-desc">
                                    <div id="description" className="project__layout-content-inner">
                                        <div>
                                            <h4 className="mb-5">How did I manage?</h4>
                                            <p>I supported and fostered the team by mentoring and managing day-to-day team practice, culture, morale, solutioning, and empowerment to improve. This included promoting best practices, culture, values, communication, documentation, and delivery while establishing clear team goals, development processes, 1:1s, and performance reviews.</p>
                                            <p>As an invested believer in the servant leader mentality, I lead from a place of empowerment and mentorship. My goal is to assist the team in creating a successful environment in any way I'm needed. Clearing their runway of obstacles and providing a safe environment is a top priority. I promote cultural values as <span className="contentHighlight">Passion</span>, <span className="contentHighlight">Communication</span>, <span className="contentHighlight">Collaboration</span>, <span className="contentHighlight">Dependability</span>, <span className="contentHighlight">Craftsmanship</span>, <span className="contentHighlight">Team Driven</span>, and <span className="contentHighlight">Flexible</span>.</p>
                                            <p>As a leader, I like to check in with people. This involves ensuring they're doing alright, conducting regular 1:1s, and just being a positive influence and helpful coach. I adjust the help I provide based on the individual's strengths and try to offer a counter balance to their needs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="layoutImages" className="project__container-images">
                                    <img
                                        src={silentHillMain}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Main"
                                    />
                                    <img
                                        src={silentHillLumbering}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Lumbering Idiot"
                                    />
                                    <img
                                        src={silentHillFate}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Their Fate is in Your Hands"
                                    />
                                    <img
                                        src={silentHillShotgun}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Shotgun Man"
                                    />
                                    <img
                                        src={silentHillTrauma}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Face Your Trauma"
                                    />
                                    <img
                                        src={silentHillBaddie}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Baddie"
                                    />
                                    <img
                                        src={walkingDeadMain}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Main"
                                    />
                                    <img
                                        src={walkingDeadShooter}
                                        className="img-fluid mb-5 project__images"
                                        alt="Silent Hill Shooter"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* DETAILS ONE */}
                        {/* <div className="project__details-container row pt-5 pb-5 mt-5">
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-2 order-md-1 order-lg-1"></div>
                            <div className="col-sm-12 col-md-6 col-lg-6 order-sm-1 order-md-2 order-lg-2">
                                <h4 className="mb-5 project__moreDetailsOne">It began with several sketches</h4>
                                    <p></p>
                            </div>
                        </div> */}
                        {/* <div
                            className="project__details-container project__additional-container project__moreImagesOne row d-flex align-items-center justify-content-center mb-5"
                            style={{
                                // minHeight: "100vh",
                                minHeight: "-webkit-fill-available",
                            }}
                        >
                            <div style={{ zIndex: "10", width: "80%" }}>
                                    <img
                                        src=""
                                        className="img-fluid mb-5 project__images"
                                        alt=""
                                    />
                            </div>
                            <img
                                src={abstractGridDashedLines}
                                alt="Screenshots of original website structure."
                                className="project__additional-abstract-image"
                            />
                        </div> */}
                        {/* END DETAILS ONE */}
                    </div>
            </section>
        </div>
    );
}
