import React from "react";

export default function About() {
    return (
        <section id="about" className="about__container anchor">
            <div className="container">
                <div className="about__capsuleContainer">
                    <div className="about__capsule"></div>
                    <div className="about__capsule"></div>
                    <div className="about__capsule"></div>
                    <div className="about__capsule"></div>
                </div>
                <div className="row about__row">
                    <div className="about__content col-lg-11 col-md-11 col-sm-12 col-12">
                        <div className="about__sectionTitleContainer sectionTitleContainer">
                            <h1 className="about__sectionTitle sectionTitle">Let's Journey Together</h1>
                        </div>
                        {/* About Content and Image */}
                        <div className="about__flex d-flex">
                            <div className="about__content about__text col-lg-7 col-md-9 col-sm-12 col-12">
                                <p>Well met, good travelers! I am truly excited to have you as my guests. I've been helping software teams grow and deliver quality products for almost 15 years. I'd love your product to be next.</p>
                                <p>I graduated from the University of Alabama (<span className="contentHighlight">ROLL TIDE!</span>) and am currently pursuing a degree in Game Design and Development from the Savannah College of Art and Design.</p>
                                <p>When I'm not making games I love spending time with my family, playing games, listening and playing music, and getting outdoors in the beautiful Pacific Northwest.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
