import file from "../assets/images/portfolio/file-evictions.png";
import lobby from "../assets/images/portfolio/lobby6.png";
import scout from "../assets/images/portfolio/scout-app.png";
import sddb from "../assets/images/portfolio/sddb-app.png";
import sols from "../assets/images/portfolio/sols.png";
import synergy from "../assets/images/portfolio/synergy.png";
import voyager from "../assets/images/portfolio/voyager.png";
import wrswebsite from "../assets/images/portfolio/wrs-website.png";
import wmwebsite from "../assets/images/portfolio/wm-website.png";
import m1web from "../assets/images/portfolio/m1-web.jpg";
import wmcustomer from "../assets/images/portfolio/wm-customer.png";
import cpd from "../assets/images/portfolio/cpd.png";
import daymaker from "../assets/images/portfolio/daymaker.jpg";
import elephante from "../assets/images/portfolio/elephante.png";
import hgbilling from "../assets/images/portfolio/hg-billing.png";
import ums from "../assets/images/portfolio/ums.png";
import my1touch from "../assets/images/portfolio/my1touch.jpg";
import ngmobile from "../assets/images/portfolio/ngm-website.png";
import proplayers from "../assets/images/portfolio/proplayers.png";
import olympics from "../assets/images/portfolio/special-olympics.png";

const webProjects = [
    {
        title: "Live Streaming App",
        description: "",
        image: "",
        roles: [
            "Engineering Mgmt",
            "Producer",
            "Project Mgmt",
            "Product Owner",
            "Unreal",
            "React",
            "AWS",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "22-23",
        company: "Genvid",
        budget: "$10mil+",
    },
    {
        title: "Vet Clinic Mgmt App",
        description: "",
        image: "",
        roles: [
            "Engineering Mgmt",
            "Producer",
            "Project Mgmt",
            "Client Account Mgmt",
            "React",
            "C#",
            "AWS",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "20-21",
        company: "Compassionate Care",
        budget: "$300k",
    },
    {
        title: "Reservation and Appt Mgmt App",
        description: "",
        image: lobby,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Client Account Mgmt",
            "Vue",
            "AWS",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2021",
        company: "CU Apps",
        budget: "$350k+",
    },
    {
        title: "Amazon Tracking App",
        description: "Scout is an application that pulls in Amazon seller data via a custom API and displays visualizations based on sales. I ran the team building the application and worked directly with the client to manage the scope and technical solutions. This included customized graphs & APIs, complex searching formulas, and customer account management all wrapped up into a modern frontend.",
        image: scout,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Design",
            "React",
            "AWS",
            "XD",
        ],
        url: "",
        alt: "",
        featured: true,
        year: "20-21",
        company: "Run AMZ",
        budget: "$1mil+",
    },
    {
        title: "Volunteer Mgmt App",
        description: "Special Olympics of Oregon needed client work on their volunteer management system. I led the team working on the backend building out technical solutions to help them track and schedule volunteers for the games and events. The system we worked on was crucial to automated the signup and vetting process for new volunteers and helping to organize the background checks for prospects.",
        image: olympics,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Python",
        ],
        url: "",
        alt: "",
        featured: true,
        year: "20-21",
        company: "Special Olympics of Oregon",
        budget: "$200k",
    },
    {
        title: "Remote Teaching App",
        description: "Over the summer 2020, STAR was in desperate need to build a new teaching delivery system for students returning to school in the fall but these students and teachers would be interacting remotely due to the COVID lockdowns. These students are special needs and would be remotely learning for the first time. There was a tight deadline and I managed the team responsible for building their remote interactive delivery system where teachers could share content during live instruction, videos, games, and more with students to interact directly in the app. We also built the teacher administration backend where they could customize academic plans for each student, create groups of students, and manage their progress.",
        image: sols,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Design",
            "React",
            "XD",
            "AWS",
        ],
        url: "",
        alt: "",
        featured: true,
        year: "2020",
        company: "STAR Autism Support",
        budget: "$500k+",
    },
    {
        title: "Student Mgmt System",
        description: "",
        image: "",
        roles: [
            "Producer",
            "Project Mgmt",
            "PHP",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2020",
        company: "STAR Autism Support",
        budget: "$2mil+",
    },
    {
        title: "Monitoring and Predictive App",
        description: "",
        image: "",
        roles: [
            "Producer",
            "Project Mgmt",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2020",
        company: "Gigaphoton",
        budget: "$1mil+",
    },
    {
        title: "Dream Visualization and Search App",
        description: "",
        image: sddb,
        roles: [
            "Engineering Mgmt",
            "Producer",
            "Project Mgmt",
            "Client Account Mgmt",
            "React",
            "Elasticsearch",
            "Figma",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "20-21",
        company: "Sleep & Dream Database",
        budget: "$500k",
    },
    {
        title: "Legal Mgmt App",
        description: "For File Evictions, I was able to set up the early project management best practice and join as the first backend developer on the project. I was able to build the early iteration of a complex legal form generator and on the design and work directly with the stakeholder on requirements. I evaluated project needs to determine infrastructure and even was a part of legal requirements fact finding and reviewing specialized court requirements to determine the technical solutions needed.",
        image: file,
        roles: [
            "Developer",
            "Project Mgmt",
            "Design",
            "XD",
            "PHP",
        ],
        url: "",
        alt: "",
        featured: true,
        year: "2019",
        company: "File Eviction",
        budget: "$400k+",
    },
    {
        title: "Vendor Ordering and Mgmt System",
        description: "",
        image: synergy,
        roles: [
            "Design",
            "Project Mgmt",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2019",
        company: "Synergy",
        budget: "$100k",
    },
    {
        title: "Space Corporate Website",
        description: "",
        image: voyager,
        roles: [
            "Designer",
            "Project Mgmt",
            "Developer",
            "PHP",
            "XD",
            "CMS",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2019",
        company: "Voyager",
        budget: "$50k",
    },
    {
        title: "Heavy Equipment Rental App",
        description: "",
        image: wrswebsite,
        roles: [
            "Engineering Mgmt",
            "Product Owner",
            "Project Mgmt",
            "Design",
            "React",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2019",
        company: "Worldwide Rental Services",
        budget: "$1mil+",
    },
    {
        title: "Mobile Equipment Mgmt App",
        description: "",
        image: "m1mobile",
        roles: [
            "Mobile",
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "React",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "15-19",
        company: "Worldwide Machinery",
        budget: "$2mil+",
    },
    {
        title: "Manufacturer Corporate Website",
        description: "",
        image: "",
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Design",
            "CMS",
            "PHP",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2018",
        company: "Superior Manufacturing",
        budget: "$100k",
    },
    {
        title: "Corporate ERP",
        description: "Worldwide Machinery's corporate ERP system was built to manage $100 million + worth of equipment. As Software Manager, I built the team and managed the project end-to-end directly with the CEO. The plan for this new system was intended to replace their decades old legacy ERP in a new backend microservice and modern frontend available online. My team not only worked on the new frontend but building out the new data model, microservice infrastructure, deployment pipeline, and implementation.",
        image: m1web,
        roles: [
            "Software Mgmt",
            "Project Mgmt",
            "Product Owner",
            "React",
            "XD",
            "C#",
            "MSSQL",
        ],
        url: "",
        alt: "",
        featured: true,
        year: "16-18",
        company: "Worldwide Machinery",
        budget: "$1mil+",
    },
    {
        title: "Rental Ecommerce Website",
        description: "",
        image: wmwebsite,
        roles: [
            "Software Mgmt",
            "Project Mgmt",
            "Product Owner",
            "React",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2017",
        company: "Worldwide Machinery",
        budget: "$500k",
    },
    {
        title: "Customer Portal",
        description: "",
        image: wmcustomer,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Design",
            "React",
            "XD",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "16-18",
        company: "Worldwide Machinery",
        budget: "$300k",
    },
    {
        title: "Legacy ERP",
        description: "",
        image: "",
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Design",
            "C#",
            "XD",
            ".Net",
            "MSSQL",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "15-19",
        company: "Worldwide Machinery",
        budget: "$1mil+",
    },
    {
        title: "Elephante ERP",
        description: "",
        image: elephante,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Python",
            "Javascript",
            "Illustrator",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2015",
        company: "Elephante LLC",
        budget: "$500k",
    },
    {
        title: "Mobile Ecommerce Website",
        description: "",
        image: cpd,
        roles: [
            "Designer",
            "Developer",
            "Illustrator",
            "Javascript",
            "HTML/CSS",
            "CMS",
            "PHP",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2014",
        company: "CPD Mobile",
        budget: "$5k",
    },
    {
        title: "Event Planning App",
        description: "",
        image: daymaker,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Designer",
            "Developer",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2014",
        company: "Daymaker",
        budget: "N/a",
    },
    {
        title: "Distributor Corporate Website",
        description: "",
        image: ums,
        roles: [
            "Engineering Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Designer",
            "Developer",
            "CMS",
            "Illustrator",
            "Photoshop",
            "HTML/CSS",
            "PHP",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2014",
        company: "United Mobile Solutions",
        budget: "$50k",
    },
    {
        title: "Billing Mgmt App",
        description: "HostGator's billing app served over 9 million customers at the time. I worked on the development team as a senior designer and developer for the project. This complex app served as the main portal for customers viewing the account details, paying their bills, and subscribing to services. This project included working on designs in Adobe based on project needs and implementing the frontend using Perl, Javascript, and HTML/CSS.",
        image: hgbilling,
        roles: [
            "Developer",
            "Designer",
            "Fireworks",
            "Perl",
            "Javascript",
            "HTML/CSS",
        ],
        url: "",
        alt: "",
        featured: true,
        year: "12-13",
        company: "HostGator",
        budget: "$10mil+",
    },
    {
        title: "cPanel Module Development",
        description: "",
        image: "",
        roles: [
            "Developer",
            "Designer",
            "Illustrator",
            "Perl",
            "Javascript",
            "HTML/CSS",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "12-13",
        company: "HostGator",
        budget: "$10mil+",
    },
    {
        title: "Telecommunications App",
        description: "",
        image: my1touch,
        roles: [
            "Developer",
            "Designer",
            "Creative Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Illustrator",
            "Python",
            "HTML/CSS",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2012",
        company: "Next-G Mobile",
        budget: "$500k",
    },
    {
        title: "Phone Ecommerce Site",
        description: "",
        image: ngmobile,
        roles: [
            "Developer",
            "Designer",
            "Creative Mgmt",
            "Project Mgmt",
            "Product Owner",
            "Photoshop",
            "HTML/CSS",
            "Python",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2012",
        company: "Next-G Mobile",
        budget: "$350k",
    },
    {
        title: "Professional Sports Coaching App",
        description: "Proplayers Live was where aspiring student athletes could get direct mentorship and coaching advice from professional NFL players and coaches. I worked with the CEO to setup the developer process, project manage, and do some design work.",
        image: proplayers,
        roles: [
            "Design",
            "Project Mgmt",
            "Photoshop",
        ],
        url: "",
        alt: "",
        featured: false,
        year: "2011",
        company: "ProPlayers Live",
        budget: "$100k",
    },
];

export default webProjects;
