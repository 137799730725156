import React from "react";
import streaming from "../../../assets/images/portfolio/ProjectStreaming.png";

export default function ProfessionalProjects() {
    return (
        <section id="proProjectsStreaming" className="proProjects__container streaming__container anchor ">
            <div className="projects__yellowFlourish"></div>
            {/* Projects List */}
            <div className="container">
                <div className="box row projects__row streaming__row">
                    <div className="proProjects__textBox col-lg-7 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2">
                        <p className="proProjects__projectTitle">Massively Interactive Live Streaming App</p>
                        <p className="proProjects__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.</p>
                        <div className="primary-button__container">
                            <div className="proProject__capsule capsule">Engineering Mgmt</div>
                            <div className="proProject__capsule capsule">Production</div>
                            <div className="proProject__capsule capsule">Project Mgmt</div>
                            <div className="proProject__capsule capsule">React</div>
                            <div className="proProject__capsule capsule">AWS</div>
                        </div>
                    </div>
                    <div className="proProjects__project-container col-lg-7 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                        <div className="streaming__imageBlocks">
                            <img
                                src={streaming}
                                className="proProjects__image proProjects__primary-image"
                                alt="Massive Streaming App"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
