import React from "react";

export default function Header() {
    return (
        <section id="home" className="header__container anchor">
            <div className="container">
                <div className="row header__row">
                    <div className="header__content col-lg-10 col-md-10 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-2 order-2">
                        {/* Title */}
                        <div className="header__section">
                            <div>
                                <div className="header__title-designer">
                                    <h1 className="header__title">
                                        Software<br/>
                                        <span className="header__outlineText">Engineering</span><br/>
                                        Manager
                                    </h1>
                                </div>
                                <div className="header__title-developer">
                                    <h2 className="header__title header__title2">...and more</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
