import React from "react";
import webProjects from "../../../data/webProjects.js";
import resumePDF from "../../../assets/pdfs/Sean-Samborski-Game-Engineering-Manager.pdf";

const Project = () => {
    const featuredProjects = webProjects.filter(project => project.featured);
    const nonFeaturedProjects = webProjects.filter(project => !project.featured);
    return (
        <section id="webProjects" className="webProjects__container anchor ">
            <div className="container">
                {featuredProjects.map((project, index) => (
                    <div key={index} className="box row projects__row webProjects__row">
                        <div className="webProjects__project-container webProjects__webContent col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2">
                            <div className="webProjects__webTitle">
                                <p>Featured Project</p>
                                <h3>{project.title}</h3>
                            </div>
                            <p className="webProjects__description">{project.description}</p>
                            <div className="webProjects__capsuleContainer capsuleContainer">
                                <div className="primary-button__container">
                                    {project.roles.map((role, roleIndex) => (
                                        <div className="webProjects__capsule capsule" key={roleIndex}>{role}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="webProjects__project-container webProjects__webImage col-lg-7 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                            <a
                                href={project.url}
                                rel="noreferrer"
                            >
                                <img
                                    src={project.image}
                                    alt={project.alt}
                                    className="webProjects__image"
                                />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className="container">
                <h2 className="allProjects__label">and more...</h2>
                <div className="table-responsive allProjects__table projects__row webProjects__row allProjects__mobile">
                    <table className="table">
                        <tbody>
                            {nonFeaturedProjects.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className="allProjects__row table__projectName">
                                        <td colspan="3" className="webProjectTitle">{project.title}</td>
                                    </tr>
                                    <tr className="allProjects__row">
                                        <td className="webProjectYear">{project.year}</td>
                                        <td>{project.company}</td>
                                        <td>{project.budget}</td>
                                    </tr>
                                    <tr className="allProjects__row table__bottomRow">
                                        <td colspan="3" className="webProjectRoles">{project.roles.join(", ")}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive allProjects__table projects__row webProjects__row allProjects__desktop">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Year</th>
                                <th scope="col">Project</th>
                                <th scope="col">Company</th>
                                <th scope="col">Role(s)</th>
                                <th scope="col">Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nonFeaturedProjects.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className="allProjects__row">
                                        <td className="webProjectYear">{project.year}</td>
                                        <td className="webProjectTitle">{project.title}</td>
                                        <td>{project.company}</td>
                                        <td className="webProjectRoles">{project.roles.join(", ")}</td>
                                        <td>{project.budget}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="primary-button__container webProjects__buttonHighlightContainer">
                        <div className="webProjects__buttonHighlight"></div>
                        <a
                            href={resumePDF}
                            download="Samborski Resume"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <button className="primary-button">View Resume</button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default Project;
