

const individualProjectDetails = [
    {
        featured: true,
        projectBusinessType: "Billing and Account Management Portal",
        projectMainImg: "/images/hostgator-image1.png",
        projectYears: "2012-2013",
        projectCompany: "HostGator",
        projectWebsite: "https://hostgator.com",
        projectBudget: "$10mil+",
        projectDescription: [
            "Hostgator provides web hosting services to over 9 million customers. They boast over 1,000 employees in two big cities in Texas; Austin and Houston. I was one of 60 developers in the IT Department and served as a Senior Front-End Developer.",
            "My job was a dual-role with half of my time on design and the half on implementation. We worked in a Perl environment using Template Toolkit as our template system. I provided work using Fireworks and Illustrator and implemented with HTML, CSS, and jQuery.",
            "Not only did I provide website design and development, I was also in charge of implementing our email campaigns.",
            "While Hostgator had a site of their own, they were also heavily involved in cPanel plugin support. They offered a wide-range of cPanel-based services to their customers. Each new partnership with a third-party resulted in numerous web pages designed and developed as well as a corresponding email campaign. As a member of the design team, I was involved in the designing of these pages and emails as well as implementing the designs into HTML, CSS, and JS.",
        ],
        projectTechnology: [
            "Developer",
            "Designer",
            "Fireworks",
            "Perl",
            "Javascript",
            "HTML/CSS",
        ],
        projectSetupTitle: "I got to use a variety of homegrown and open-source components like data tables.",
        projectSetupItem: [
            "Hostgator was very open to the idea of sharing ideas and utilizing lots of different kinds of technologies. Even if it was just an experiment, we were ready to try things out and see how they worked for us. It gave me a great opportunity to try new things and grow as a developer."
        ],
        projectLayoutDetails: "22-23",
        projectImages: [],
        projectDetailsTitle: "Responsiveness for easy accessibility",
        projectDetailsOne: [
            "A lot of Hostgator was tabular data returned to the user. So when we needed things like tables, I was in charge of making them work for us on all kinds of devices. Anyone who knows front-end knows working with tables isn't everyone's idea of a good time. Even the most annoying parts of HTML isn't enough to make me shy away.",
        ],
        projectDetailsOneImages: [],
        projectDetailsTwo: [],
        projectDetailsTwoImages: [],
        projectDetailsThree: [],
        projectDetailsThreeImages: [],
    },
];

// Function to update image paths based on projectCompany
const updateImagePaths = (projectDetails) => {
    return projectDetails.map((project) => {
      const name = project.projectCompany;
      const updateImagePath = (imagePath) =>
        `../../public/images/projects/${name}/${imagePath}`;

      project.projectImages = project.projectImages.map(updateImagePath);
      project.projectDetailsOneImages = project.projectDetailsOneImages.map(updateImagePath);
      project.projectDetailsTwoImages = project.projectDetailsTwoImages.map(updateImagePath);
      project.projectDetailsThreeImages = project.projectDetailsThreeImages.map(updateImagePath);

      return project;
    });
  };

  // Call the function to update image paths for all projects in the array
  const updatedProjects = updateImagePaths(individualProjectDetails);

  export default updatedProjects;


// export default individualProjectDetails;
