import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import linkedinIcon from "../../../assets/icons/linkedin-in-brands.png";
import emailIcon from "../../../assets/icons/email-add-black.png";

export default function Sidebar() {
    // Controls offcanvas menu open/close
    const [show, setShow] = useState(false);
    const handleClose = () => setTimeout(() => setShow(false), 1000);
    const handleShow = () => setShow(true);
    const numberOfLines = 2;

    useEffect(() => {
        console.log(show);
    }, [show]);

    return (
        <div className="sidebar__container">
            <div className="sidebar__topright">
                <div className="sidebar__toprightContainer">
                    <div className="sidebar__toprightInner">
                        <p>Software Leader</p>
                        <p>Producer</p>
                        <p>Project Manager</p>
                    </div>
                </div>
            </div>
            {/* Sidebar Menu */}
            <div id="SidebarMenu" className="sidebar__sidebar">
                {/* Menu Hamburger */}
                <div className="sidebar__menu-icon-section">
                    {/* Offset Menu Button Sidebar */}
                    <button className="sidebar__menu-button" onClick={handleShow}>
                        <div className="sidebar__minilinesContainer minilinesContainer">
                            {Array.from({ length: numberOfLines }, (_, index) => (
                                <div key={index} className="sidebar__minilines minilines"></div>
                            ))}
                        </div>
                    </button>
                    {/* Offset Menu */}
                    <Offcanvas
                        show={show}
                        onHide={handleClose}
                        className="sidebar__offcanvas-start"
                    >
                        <Offcanvas.Header>
                            <div onClick={handleClose} className="borderless-button__container sidebar__close-menu">
                                <hr className="borderless-button__line" />
                                <button className="borderless-button">Close</button>
                            </div>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <nav>
                                <ul className="sidebar__menu-items-list">
                                    <li onClick={handleClose}>
                                        <a href="/#home">
                                            <h1>Home</h1>
                                        </a>
                                    </li>
                                    <li onClick={handleClose}>
                                        <a href="/#about">
                                            <h1>About</h1>
                                        </a>
                                    </li>
                                    <li onClick={handleClose}>
                                        <a href="/#proProjectsBorderlands">
                                            <h1>Game Projects</h1>
                                        </a>
                                    </li>
                                    <li onClick={handleClose}>
                                        <a href="/#webProjects">
                                            <h1>Web Projects</h1>
                                        </a>
                                    </li>
                                    <li onClick={handleClose}>
                                        <a href="/#toolkit">
                                            <h1>Toolkit & Skills</h1>
                                        </a>
                                    </li>
                                    <li onClick={handleClose}>
                                        <a href="/#contact">
                                            <h1>Contact</h1>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
                {/* Email */}
                <a className="sidebar__email" href="mailto:sean.samborski@live.com">sean.samborski@live.com</a>
                {/* Footer Icons */}
                <div className="sidebar__menu-footer-icons">
                    <a
                        href="https://www.linkedin.com/in/seansamborski/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <img
                            src={linkedinIcon}
                            alt="Linkedin Icon."
                            className="sidebar__menu-contact-icon"
                        />
                    </a>
                    <a href="mailto:sean.samborski@live.com">
                        <img
                            src={emailIcon}
                            alt="Email Icon."
                            className="sidebar__menu-contact-icon"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}
